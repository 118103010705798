import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'summary', element: <div /> },
        {
          path: 'giving',
          children: [
            { path: '', element: <div /> },
            { path: 'categories', element: <div /> },
            { path: 'fund-raising', element: <div /> },
          ],
        },
        {
          path: 'websites',
          children: [
            { path: '', element: <div /> },
            { path: 'new', element: <div /> },
            { path: 'settings', element: <div /> },
          ],
        },
        {
          path: 'users',
          children: [
            { path: '', element: <div /> },
            { path: 'invite', element: <div /> },
          ],
        },
        {
          path: 'broadcasts',
          children: [
            { path: 'all', element: <div /> },
            { path: 'new', element: <NewBroadcast /> },
            { path: 'settings', element: <div /> },
          ],
        },
        {
          path: 'posts',
          children: [
            { path: '', element: <div /> },
            { path: ':id', element: <div /> },
            { path: 'new', element: <div /> },
            { path: ':id/comments', element: <div /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'confirm-email', element: <ConfirmEmail /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [{ element: <Navigate to="/dashboard" replace />, index: true }],
    },
    {
      path: 'back-office',

      element: (
        <RoleBasedGuard hasContent isPermitted={(user) => true}>
          <LogoOnlyLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: '', element: <BackOffice /> },
        { path: 'quizzes', element: <BackOffice /> },
        { path: 'quizzes/:quizId', element: <EditQuiz /> },
        { path: 'quizzes/:quizId/moderator', element: <QuizModerator /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));

// BROADCAST
const NewBroadcast = Loadable(lazy(() => import('../pages/dashboard/broadcast/NewBroadcast')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const ConfirmEmail = Loadable(lazy(() => import('../pages/ConfirmEmail')));

// BACK OFFICE
const BackOffice = Loadable(lazy(() => import('../pages/back-office')));
const QuizModerator = Loadable(lazy(() => import('../pages/back-office/QuizModerator')));
const EditQuiz = Loadable(lazy(() => import('../pages/back-office/EditQuiz')));
