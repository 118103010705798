import { API_URLS } from './constants';
import axios from 'axios';

const httpClient = axios.create({
  baseURL: API_URLS.WEB,
});

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  if (config.headers) config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default httpClient;
