// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIconSVG = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />;

const ICONS = {
  overview: getIcon('eva:pie-chart-2-fill'),
  post: getIcon('dashicons:welcome-write-blog'),
  blog: getIcon('ic_blog'),
  giving: getIcon('fa6-solid:money-check-dollar'),
  website: getIcon('foundation:web'),
  user: getIcon('eva:people-fill'),
  settings: getIcon('clarity:settings-solid-alerted'),
  broadcast: getIcon('fluent:location-live-24-filled'),
  cart: getIconSVG('ic_cart'),
  chat: getIconSVG('ic_chat'),
  mail: getIconSVG('ic_mail'),
  kanban: getIconSVG('ic_kanban'),
  banking: getIconSVG('ic_banking'),
  booking: getIconSVG('ic_booking'),
  invoice: getIconSVG('ic_invoice'),
  calendar: getIconSVG('ic_calendar'),
  ecommerce: getIconSVG('ic_ecommerce'),
  analytics: getIconSVG('ic_analytics'),
  dashboard: getIconSVG('ic_dashboard'),
  menuItem: getIconSVG('ic_menu_item'),
};

const navConfig = [
  {
    subheader: 'dashboard',
    items: [
      // { title: 'overview', path: PATH_DASHBOARD.overview, icon: ICONS.overview },

      // POST
      // {
      //   title: 'post',
      //   path: PATH_DASHBOARD.post.root,
      //   icon: ICONS.post,
      //   children: [
      //     { title: 'all posts', path: PATH_DASHBOARD.post.all },
      //     { title: 'new post', path: PATH_DASHBOARD.post.new },
      //   ],
      // },

      // GIVING
      // {
      //   title: 'giving',
      //   path: PATH_DASHBOARD.giving.root,
      //   icon: ICONS.giving,
      //   children: [
      //     { title: 'report', path: PATH_DASHBOARD.giving.report },
      //     { title: 'categories', path: PATH_DASHBOARD.giving.categories },
      //     { title: 'fund raising', path: PATH_DASHBOARD.giving.fundRasing },
      //   ],
      // },

      // BLOG
      {
        title: 'Broadcast',
        path: PATH_DASHBOARD.broadcast.root,
        icon: ICONS.broadcast,
        children: [
          { title: 'go live', path: PATH_DASHBOARD.broadcast.new },
          { title: 'past events', path: PATH_DASHBOARD.broadcast.all },
          { title: 'settings', path: PATH_DASHBOARD.broadcast.settings },
        ],
      },

      // INVOICE
      // {
      //   title: 'website',
      //   path: PATH_DASHBOARD.website.root,
      //   icon: ICONS.website,
      //   children: [
      //     { title: 'websites', path: PATH_DASHBOARD.website.all },
      //     { title: 'new website', path: PATH_DASHBOARD.website.new },
      //     { title: 'Settings', path: PATH_DASHBOARD.website.settings },
      //   ],
      // },

      // USER
      // {
      //   title: 'team',
      //   path: PATH_DASHBOARD.team.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'users', path: PATH_DASHBOARD.team.all },
      //     { title: 'invite user', path: PATH_DASHBOARD.team.invite },
      //   ],
      // },
    ],
  },
];

export default navConfig;
