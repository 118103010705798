import { isProduction } from './environment';

export const API_URLS = {
  WEB: isProduction() ? 'https://api.onepulpit.com/api/v1' : 'http://localhost:5000/api/v1',
  AUDIO_INGEST_SOCKET: isProduction() ? 'wss://audio-ingest.onepulpit.com' : 'ws://localhost:2000',
  AUDIO_EDGE_SOCKET: isProduction() ? 'wss://audio-edge.onepulpit.com' : 'ws://localhost:1000',
  LISTENERS_HTTP: isProduction() ? 'https://listeners.onepulpit.com' : 'http://localhost:4000',
  LISTENERS_SOCKET: isProduction() ? 'wss://listeners.onepulpit.com' : 'ws://localhost:4000',
  CHATS_SOCKET: isProduction() ? 'wss://chats.onepulpit.com' : 'ws://localhost:7000',
};
