// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: path(ROOTS_DASHBOARD, '/summary'),
  giving: {
    root: path(ROOTS_DASHBOARD, '/giving'),
    report: path(ROOTS_DASHBOARD, '/giving/report'),
    categories: path(ROOTS_DASHBOARD, '/giving/categories'),
    fundRasing: path(ROOTS_DASHBOARD, '/giving/fund-raising'),
  },
  post: {
    root: path(ROOTS_DASHBOARD, '/posts'),
    all: path(ROOTS_DASHBOARD, '/posts/all'),
    new: path(ROOTS_DASHBOARD, '/posts/new'),
  },
  broadcast: {
    root: path(ROOTS_DASHBOARD, '/broadcasts/'),
    all: path(ROOTS_DASHBOARD, '/broadcasts/all'),
    new: path(ROOTS_DASHBOARD, '/broadcasts/new'),
    settings: path(ROOTS_DASHBOARD, '/broadcasts/settings'),
  },
  website: {
    root: path(ROOTS_DASHBOARD, '/websites'),
    all: path(ROOTS_DASHBOARD, '/websites/all'),
    new: path(ROOTS_DASHBOARD, '/websites/new'),
    settings: path(ROOTS_DASHBOARD, '/websites/settings'),
  },
  team: {
    root: path(ROOTS_DASHBOARD, '/users'),
    all: path(ROOTS_DASHBOARD, '/users/all'),
    invite: path(ROOTS_DASHBOARD, '/users/invite'),
  },
  event: {
    root: path(ROOTS_DASHBOARD, '/events'),
    all: path(ROOTS_DASHBOARD, '/events'),
    new: path(ROOTS_DASHBOARD, '/events/new'),
  },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
