import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Grid, Card, Stack, Typography, Alert } from '@mui/material';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { FC, useState } from 'react';
import httpClient from 'src/services/httpClient';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(4),
}));

type CreateOrganizationData = {
  organizationName: string;
  contactPhone: string;
  alias: string;
};

interface Props {
  onSuccess?: () => void;
}

const CreateOrganizationForm: FC<Props> = ({ onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');

  const NewOrganizationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .required('Organization name title is required')
      .max(100, 'Organization name must be less than 100 characters'),
    contactPhone: Yup.string().required('Contact number is required'),
    alias: Yup.string().required('Alias is required').max(25, 'Alias must be less than 100 characters'),
  });

  const defaultValues = {
    organizationName: '',
    contactPhone: '',
    alias: '',
  };

  const methods = useForm<CreateOrganizationData>({
    resolver: yupResolver(NewOrganizationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: CreateOrganizationData) => {
    setSubmissionError('');
    try {
      await httpClient.post('/organizations', data);
      if (onSuccess) onSuccess();
    } catch (error) {
      setSubmissionError(error.response.data.message);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <LabelStyle>Create your first ministry to begin</LabelStyle>
              {submissionError && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  {submissionError}
                </Alert>
              )}

              <Stack spacing={3}>
                <RHFTextField name="organizationName" label="Ministry Name" />
                <RHFTextField name="contactPhone" label="Contact Number" />
                <RHFTextField name="alias" label="Your prefered Alias e.g @my_awesome_ministry" />
              </Stack>
              <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                  Create
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default CreateOrganizationForm;
