import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Alert, Box } from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import useAuth from 'src/hooks/useAuth';
import LogoOnlyLayout from '../LogoOnlyLayout';
import CreateOrganizationForm from 'src/sections/@dashboard/CreateOrganizationForm';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(20),
}));

const NewOrganizationStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(20),
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(30),
  },
}));

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { organizationAuthorizations, user } = useAuth();

  const [open, setOpen] = useState(false);

  if (!user!.isEmailConfirmed)
    return (
      <LogoOnlyLayout hasChildren>
        <InfoStyle>
          <Alert severity="success">Welcome to one pulpit, Please check your email to confirm your email address</Alert>
        </InfoStyle>
      </LogoOnlyLayout>
    );

  if (user?.isEmailConfirmed && organizationAuthorizations?.length === 0)
    return (
      <LogoOnlyLayout hasChildren>
        <NewOrganizationStyle>
          <CreateOrganizationForm onSuccess={() => window.location.reload()} />
        </NewOrganizationStyle>
      </LogoOnlyLayout>
    );

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {organizationAuthorizations.length > 0 && user!.isEmailConfirmed && (
        <>
          <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

          <MainStyle collapseClick={collapseClick}>
            <Outlet />
          </MainStyle>
        </>
      )}
    </Box>
  );
}
